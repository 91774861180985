import React, { Fragment } from 'react';
import style from "./ServicesSummary.module.css";

const AboutLanding = () => {
    return <Fragment>
        <section className={style.summary}>
            <h1>About AdminCom</h1>
            <p>Welcome to your trusted partner in website building and administration services. AdminCom is dedicated to helping individuals and small businesses establish a strong online presence and streamline their operations.
                Administration and computer services AdminCom is committed to delivering customized solutions that meet your unique needs.</p>

            <h1>Services</h1>
            <p>AdminCom offers a comprehensive range of services designed to support your online and administrative needs:</p>
            <ul>
                <li><strong>Website Design and Development</strong></li>
                <li><strong>Website Maintenance</strong></li>
                <li><strong>Data entry</strong></li>
                <li><strong>Ad Creation</strong></li>
                <li><strong>Administrative Support and virtual assistance</strong></li>
            </ul>

            <h1>Contact</h1>
            <p>Ready to transform your online presence and streamline your business operations? Get in touch via the contact form bellow.</p>
        </section>
    </Fragment>
}

export default AboutLanding;