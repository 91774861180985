import React from "react";
import NavBar from "./NavBar";
import styles from "./Header.module.css";

const Header = (props) => {
  return (
    <>
      <header className={styles.header}>
        <h1>AdminCom</h1>
        <div className={styles.nav}>
          <NavBar />
        </div>
      </header>
    </>
  );
};

export default Header;
