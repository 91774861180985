import React from "react";
import styles from "./AllServices.module.css";

const AllServicesLanding = () => {
    return (
        <div style={{marginTop: '20px'}}>
            <div className={styles["main-image"]}>
                <img
                    src={require("../images/womanworking.jpg")}
                    alt="Woman working on a laptop"
                ></img>
            </div>
            <section className={styles.summary}>

                <h2>Unlock Your Business Potential with Expert Administration and Website Services</h2>
                <p>Are you struggling to manage administrative tasks and maintain your website? AdminCom is specialized in providing top-notch administration and website help services tailored to meet the unique needs of small businesses and individual entrepreneurs.
                    Your efficiency, productivity, and online presence is the main priority.</p>
                <p>Ready to elevate your business with our virtual assistance and website services? Use the contact form today to learn more about how this organization can help you achieve your goals.</p>
            </section>
        </div>
    )
};

export default AllServicesLanding;