import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import styles from './Navbar.module.css';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';

export default function Navbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <nav className={styles.Navbar}>
        <FaBars className={styles.Hamburger} onClick={toggleMenu} />
        <ul className={`${styles.Menu} ${menuOpen ? styles.active : ''}`}>
          <Button href="/" className={styles.Link}>
            {'Home'}
          </Button>
          <Button href="/about" className={styles.Link}>
            {'About'}
          </Button>
          <Button href="/services" className={styles.Link}>
            {'Services'}
          </Button>
          <Button href="/contact" className={styles.Link}>
            {'Contact'}
          </Button>
        </ul>
        <div>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
          </Menu>
        </div>
      </nav>
    </>
  );
}