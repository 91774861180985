import React from "react";
import Header from "./components/Header/Header";
import About from "./components/About/About";
import Image from "./components/Header/Image";
import ValidationContactForm from "./components/Form/ContactForm";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import BottomNav from "./components/BottomNav/BottomNavigation";
import AllServices from "./components/Services/AllServices";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AboutLanding from "./components/About/AboutLandingPage";
import AllServicesLanding from "./components/Services/AllServicesLanding";
import Navbar from "./components/Header/NavBar";


function App() {

  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={
          <>
            <Header />
            <Image />
            <AboutLanding />
            <AllServicesLanding />
            <ValidationContactForm />
            <BottomNav />
          </>
        }>
        </Route>
        <Route path="about" element={
          <>
            <Header />
            <Image />
            <About />
            <BottomNav />
          </>
        }>
        </Route>
        <Route path="services" element={
          <>
            <Header />
            <AllServices />
            <BottomNav />
          </>
        }>
        </Route>
        <Route path="contact" element={
          <>
            <Header />
            <ValidationContactForm />
            <BottomNav />
          </>
        }>
        </Route>
        <Route path="bottomnav" element={
          <>
            <Header />
            <Image />
            <BottomNav />
          </>
        }>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
