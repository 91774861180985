import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Computer, Contacts, Copyright, Home } from '@mui/icons-material';
import { Typography } from '@mui/material';

const BottomNav = (props) => {
    const [value, setValue] = React.useState(0);

    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignSelf: 'center', paddingTop: '30px' }}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                <Typography variant="h10" padding="15px" color="#414a4c"> <Copyright />Admincom 2024 </Typography>
                <BottomNavigationAction label="Home" icon={<Home />} />
                <BottomNavigationAction label="Services" icon={<Computer />} />
                <BottomNavigationAction label="Contacts" icon={<Contacts />} />
            </BottomNavigation>
        </Box>
    );
}

export default BottomNav;