import React, { Fragment } from "react";
import styles from "./Header.module.css";

const Image = (props) => {
    return (
        <Fragment>
            <div className={styles["main-image"]}>
                <img
                    src={require("../images/office.jpg")}
                    alt="Office interior"
                ></img>
            </div>
        </Fragment>
    );
};

export default Image;