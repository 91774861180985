import React, { Fragment, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
// import '//i18n';
// import { useTranslation } from "react-i18next";
import '@fontsource/roboto/400.css';
import styles from './ContactForm.module.css';
import { Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import emailjs from '@emailjs/browser';

export default function ValidationContactForm({ defaultValue }) {
    const form = useRef();
    const [clientName, setClientName] = useState("");
    const [clientEmail, setClientEmail] = useState("");
    const [clientMessage, setClientMessage] = useState("");
    // const { t } = useTranslation();

    const sendEmail = (e) => {
        e.preventDefault();

        if (clientName) {
            console.log(clientName);
            setClientName("");
        }

        if (clientEmail) {
            console.log(clientEmail);
            setClientEmail("");
        }

        if (clientMessage) {
            console.log(clientMessage);
            setClientMessage("");
        }

        emailjs
            .sendForm('service_6o79d02', 'template_fxm8wq8', form.current, {
                publicKey: 'FYeqyiqLIKBIBiLfN',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <Fragment>
            <div className={styles.text}>
                <div>
                    <Box className={styles.box}
                        component="form"
                        sx={{
                            '& .MuiTextField-root': {
                                m: 1,
                                width: '25ch',
                                display: "flex",
                            }
                        }}
                        noValidate
                        autoComplete="off"
                        ref={form}
                        defaultValue={defaultValue ? defaultValue : ""}
                    >
                        <div>
                            <Typography variant="h4" className={styles.contact}>
                                Contact
                            </Typography>
                            <div>
                                <TextField
                                    label="NAME"
                                    helperText="Please enter your name"
                                    color="success"
                                    value={clientName}
                                    defaultValue=""
                                    onChange={(e) => setClientName(e.target.value)}
                                />
                                <TextField
                                    label="EMAIL"
                                    helperText="Please enter your email"
                                    color="success"
                                    value={clientEmail}
                                    defaultValue=""
                                    onChange={(e) => setClientEmail(e.target.value)}
                                />
                            </div>
                            <div>
                                <TextField
                                    id="outlined-multiline-static"
                                    helperText="Please enter your message"
                                    color="success"
                                    label="MESSAGE"
                                    multiline
                                    rows={4}
                                    value={clientMessage}
                                    defaultValue=""
                                    onChange={(e) => setClientMessage(e.target.value)}
                                />
                            </div>
                        </div>
                    </Box>
                    <div className={styles.Button}>
                        <Button variant="outlined" size="large" color="success"
                            endIcon={<SendIcon />} type="submit" onClick={sendEmail}>
                            Send
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}