import React from "react";
import styles from "./AllServices.module.css";

const AllServices = () => {
    return (
        <div className={styles.box}>
            <div className={styles["main-image"]}>
                <img
                    src={require("../images/womanworking.jpg")}
                    alt="Woman working on a laptop"
                ></img>
            </div>
            <section className={styles.summary}>

                <h1>Comprehensive Virtual Assistance and Website Services</h1>
                <p>Welcome to our professional virtual assistant services, specializing in data entry, administration, and website building. We are dedicated to providing solutions that enhance your efficiency, streamline operations, and help your business thrive. Discover our wide range of services designed to support your unique needs.</p>

                <h2>Our Services</h2>
                <p>We offer a versatile suite of virtual assistant and website services, including:</p>
                <ul>
                    <li><strong>Virtual Assistant Services:</strong> Ready to handle a variety of tasks, from managing your schedule and emails to conducting research and customer support.</li>
                    <li><strong>Data Entry and Administration:</strong> Streamline your administrative tasks with our accurate and efficient data entry services, document management, and other administrative support to keep your business organized and running smoothly.</li>
                    <li><strong>Website Design and Development:</strong> We create stunning, responsive websites tailored to your business needs, from simple informational sites to complex e-commerce platforms.</li>
                    <li><strong>Website Maintenance:</strong> Keep your website up-to-date and secure with our ongoing maintenance services, including content updates, security checks, and performance optimization.</li>
                    <li><strong>Ad Creation:</strong> Engage your audience with high-quality advertisement creation services, including market research and creative design, tailoring to your brand’s voice and goals.</li>
                </ul>

                <h2>Why Choose Us?</h2>
                <p>Our dedication to quality and customer satisfaction distinguishes us from the rest. Here’s why you should choose our services:</p>
                <ul>
                    <li><strong>Experienced Professionals</strong></li>
                    <li><strong>Tailored Solutions</strong></li>
                    <li><strong>Reliability</strong></li>
                    <li><strong>Competitive Pricing</strong></li>
                    <li><strong>Exceptional Support</strong></li>
                </ul>

                <h2>Contact Us</h2>
                <p>Ready to elevate your business with our virtual assistant and website services? Contact us today to learn more about how we can help you achieve your goals. Reach out to us via the contact form. We look forward to partnering with you for success.</p>

                {/* <h2>Lorem ipsum dolor sit amet</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <p>
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat.
                </p>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <p>
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat.
                </p> */}
            </section>
        </div>
    )
};

export default AllServices;