import React, { Fragment } from "react";
import styles from "../Services/AllServices.module.css";

const ServicesSummary = () => {
  return (
    <Fragment>
      {/* <div className={style["main-image"]}>
        <img
          src={require("../images/laptop.jpg")}
          alt="Notebook on a desk"
        ></img>
      </div> */}
      <section className={styles.summary}>
        <h1>About Us</h1>
        <p>Welcome to AdminCom, your trusted partner in website building and administration services. AdminCom is dedicated to helping individuals and small businesses establish a strong online presence and streamline their operations. 
          Administration and computer services AdminCom is committed to delivering customized solutions that meet your unique needs.</p>

        <h2>Our Mission</h2>
        <p>Our mission is to empower our clients with cutting-edge website design, development, and administrative support that enhances their digital footprint and operational efficiency. AdminCom strive to provide innovative solutions that drive growth and success for businesses.</p>

        <h2>What We Do</h2>
        <p>At AdminCom, we offer a comprehensive range of services designed to support your online and administrative needs:</p>
        <ul>
          <li><strong>Website Design and Development:</strong> From concept to launch, creating visually appealing, responsive, and user-friendly websites tailored to your brand and business objectives.</li>
          <li><strong>Website Maintenance:</strong> We provide ongoing maintenance services to ensure your website remains secure, up-to-date, and optimized for performance, allowing you to focus on your core business activities.</li>
          <li><strong>Ad Creation:</strong> Engage your visitors with compelling ads.</li>
          <li><strong>Administrative Support:</strong> Our administrative services include data entry, document management, virtual assistance, and other essential tasks to help you manage your business efficiently.</li>
        </ul>

        <h2>Why Choose this company?</h2>
        <p>Choosing AdminCom means choosing a partner who is dedicated to your success.</p>
        <ul>
          <li><strong>Expertise:</strong> You choose for professionals with knowledge in website development and administrative services, ensuring quality results.</li>
          <li><strong>Customized Solutions:</strong> Every business is unique, and AdminCom tailor the services to meet your specific needs and goals.</li>
          <li><strong>Commitment to Quality:</strong> You choose for exceptional services that exceed your expectations and drive your business forward.</li>
          <li><strong>Reliable Support:</strong> Availability to assist you with any questions or concerns, providing peace of mind and seamless service.</li>
          <li><strong>Competitive Pricing:</strong> Affordable pricing without compromising on quality, ensuring you receive the best value for your investment.</li>
        </ul>

        <h2>Contact</h2>
        <p>Ready to transform your online presence and streamline your business operations? Get in touch via the contact form. We look forward to partnering with you for success.</p>
      </section>
    </Fragment>
  );
};

export default ServicesSummary;
